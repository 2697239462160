import React from "react";
import Slider from "./Slider";
import {
  Divider,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import MultiPostSliderItem from "./MultiPostSliderItem";

const MultiPostSlider = ({ title, posts, arrowPos }) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const isTablet = useMediaQuery(muiTheme.breakpoints.up("md"));
  const isDesktop = useMediaQuery(muiTheme.breakpoints.up("lg"));
  const sliderSettings = {
    lazyLoad: true,
    slidesToShow: isDesktop ? 3 : isTablet ? 2 : 1,
    slidesToScroll: isDesktop ? 3 : isTablet ? 2 : 1,
  };

  return (
    <div className={classes.root} aria-label="latest-periodical-list-root">
      <div className={classes.headingRoot}>
        <Typography
          variant="h4"
          className={classes.headingText}
          align={isDesktop ? "left" : "center"}
        >
          {title}
        </Typography>
        <Divider className={classes.headingDivider} />
      </div>
      <Slider settings={sliderSettings} arrowPos={arrowPos} negativeMargin>
        {posts.map((post, index) => (
          <MultiPostSliderItem key={index} post={post} />
        ))}
      </Slider>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      marginRight: `-${theme.spacing(4)}px`,
    },
  },
  headingRoot: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(3),
    },
  },
  headingText: {
    fontFamily: "Open Sans",
  },
  headingDivider: {
    overflow: "hidden",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      marginLeft: `-${theme.spacing(2)}px`,
      marginRight: `-${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(4)}px`,
    },
  },
}));

export default MultiPostSlider;
