import React from "react";
import { CardMedia, Grid, Paper, Typography } from "@material-ui/core";
import { CardActionArea } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import useLanguage from "../hooks/useLanguage";
import { convertSlashDateToMoment, formatMomentDateByLang } from "../utils";

const MultiPostSliderItem = ({ post, fullWidth }) => {
  const classes = useStyles(fullWidth);
  const lang = useLanguage();

  let date = "";
  if (post.eventFieldGroup?.eventStartDate) {
    date = formatMomentDateByLang(
      convertSlashDateToMoment(
        post.eventFieldGroup?.eventStartDate.split(" ")[0]
      ),
      lang
    );
  }

  let eventInfo = "";
  if (post.eventFieldGroup?.eventStartDate || post.locationGroup?.location) {
    eventInfo = [date, post.locationGroup.location].join(" | ");
  }

  return (
    <Grid
      container
      item
      lg={fullWidth ? 12 : 11}
      className={classes.root}
      aria-label="latest-periodical-root"
    >
      <CardActionArea className={classes.actionArea} to={post.uri}>
        <Paper variant="outlined" square className={classes.paper}>
          {post.contentGenericGroup?.thumbnail?.sourceUrl && (
            <CardMedia
              className={classes.image}
              component="img"
              src={post.contentGenericGroup?.thumbnail?.sourceUrl}
            />
          )}
        </Paper>
        <Typography variant="body1" className={classes.title}>
          {post.title}
        </Typography>
        {eventInfo && (
          <Typography variant="body2" className={classes.description}>
            {eventInfo}
          </Typography>
        )}
      </CardActionArea>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  paper: {
    // minHeight: "260px",
  },
  image: {
    padding: "0",
    objectFit: "cover",
    // height: "260px",
  },
  title: {
    fontFamily: "Source Serif Pro",
    fontWeight: 400,
    marginTop: theme.spacing(1),
  },
}));

export default MultiPostSliderItem;
