import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PostListItem from "./PostListItem";

const PostList = ({ posts }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.root}>
      {posts.map((article, index) => {
        return (
          <Grid key={index} className={classes.gridItem} item>
            <PostListItem key={index} article={article} />
          </Grid>
        );
      })}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    // [theme.breakpoints.up("sm")]: {
    //   marginBottom: theme.spacing(4),
    // },
  },
  gridItem: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

export default PostList;
