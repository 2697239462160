import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Divider,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Link } from "gatsby-material-ui-components";

const SectionGridLayout = ({ title, categories, anchorId, color }) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const isPhone = useMediaQuery(muiTheme.breakpoints.up("xs"));
  const isTablet = useMediaQuery(muiTheme.breakpoints.up("md"));
  const isDesktop = useMediaQuery(muiTheme.breakpoints.up("lg"));

  return (
    <div id={decodeURI(anchorId)} className={classes.root}>
      <div className={classes.headingRoot}>
        <Typography
          variant="h4"
          className={classes.headingText}
          align={isDesktop ? "left" : "center"}
        >
          {title}
        </Typography>
        <Divider className={classes.headingDivider} />
      </div>
      <Grid container spacing={isTablet ? 5 : 2}>
        {categories.map((item, index) => {
          let cardClassName = `${classes.card}`;
          if (item.bottomLineColor === "blue")
            cardClassName = `${classes.card} ${classes.blueCard}`;
          if (item.bottomLineColor === "green")
            cardClassName = `${classes.card} ${classes.greenCard}`;
          return (
            <Grid
              item
              key={`publication-section-${index}`}
              xs={12}
              sm={6}
              md={4}
            >
              <Link to={item.uri} underline="none">
                <Card className={cardClassName} variant="outlined" square>
                  <CardActionArea className={classes.cardActionArea}>
                    <CardContent className={classes.cardContent}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="space-between"
                        className={classes.gridContainer}
                      >
                        <Grid item xs={9}>
                          <Typography
                            variant={isPhone ? "h5" : "body2"}
                            className={classes.categoryName}
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          container
                          justifyContent="flex-end"
                          alignItems={isPhone ? "flex-end" : "center"}
                        >
                          <img
                            src={item.banner_url}
                            alt="banner"
                            style={{ maxWidth: "100%", height: "auto" }}
                          />
                          {/* <span>this is an image</span> */}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
    // [theme.breakpoints.up("lg")]: {
    //   marginRight: `-${theme.spacing(4)}px`,
    // },
  },
  headingRoot: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(4),
    },
  },
  headingText: {
    fontFamily: "Open Sans",
  },
  headingDivider: {
    overflow: "hidden",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      marginLeft: `-${theme.spacing(2)}px`,
      marginRight: `-${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(8)}px`,
    },
  },
  card: {
    backgroundColor: theme.palette.info.main,
    [theme.breakpoints.down("sm")]: {
      borderLeftColor: theme.palette.error.main,
      borderLeftWidth: 3,
    },
    [theme.breakpoints.up("md")]: {
      borderBottomColor: theme.palette.error.main,
      borderBottomWidth: 6,
    },
  },
  blueCard: {
    [theme.breakpoints.down("sm")]: {
      borderLeftColor: theme.palette.warning.main,
    },
    [theme.breakpoints.up("md")]: {
      borderBottomColor: theme.palette.warning.main,
    },
  },
  greenCard: {
    [theme.breakpoints.down("sm")]: {
      borderLeftColor: theme.palette.success.main,
    },
    [theme.breakpoints.up("md")]: {
      borderBottomColor: theme.palette.success.main,
    },
  },
  cardActionArea: {
    display: "flex",
    flexDirection: "row",
    minHeight: 100,
    [theme.breakpoints.up("sm")]: {
      minHeight: 220,
      flexDirection: "column",
    },
  },
  cardContent: {
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("xs")]: {
      minHeight: 160,
    },
  },
  gridContainer: {
    flex: 1,
  },
  categoryName: {
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
}));

export default SectionGridLayout;
