import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

import React, { useEffect, useState, useContext } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

import Container from "../components/Container";
import Layout from "../components/layout";
import Banner from "../components/Banner";
import Divider from "../components/Divider";
import SectionGridLayout from "../components/SectionGridLayout";
import MultiPostSlider from "../components/MultiPostSlider";

import PostList from "../components/PostList";
import FeaturedPostSlider from "../components/FeaturedPostSlider";

import useUrl from "../hooks/useUrl";
import useLanguage from "../hooks/useLanguage";
import useWpRestApi from "../hooks/useWpRestApi";

import i18nContext from "../i18n-context";

const HomePage = ({ data, pageContext }) => {
  const url = useUrl();
  const lang = useLanguage();
  const i18n = useContext(i18nContext)[lang];
  const isPhone = useMediaQuery(useTheme().breakpoints.down("xs"));
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));
  const [isFeaturedArticlesAvailable, setFeaturedArticlesAvailable] = useState(
    true
  );
  const [isLatestArticlesAvailable, setLatestArticlesAvailable] = useState(
    true
  );

  const featuredArticles = useWpRestApi(
    "/featured-posts?featured=1",
    setFeaturedArticlesAvailable
  );
  const latestArticles = useWpRestApi(
    "/featured-posts",
    setLatestArticlesAvailable
  );

  const bannerTitle = pageContext.homepageGroup?.bannerTitle || "";
  const bannerUrl =
    (isPhone
      ? pageContext.homepageGroup?.homepageBannerMobile?.sourceUrl
      : pageContext.homepageGroup?.homepageBanner?.sourceUrl) || "";
  const latestPeriodicals = data.allWpPeriodical?.nodes || [];
  const dividerText = pageContext.homepageGroup?.learnMoreDescription || "";
  const dividerButtonText =
    pageContext.homepageGroup?.learnMoreCta?.title || "";
  const buttonHref = pageContext.homepageGroup?.learnMoreCta?.url || "";
  const pageTranslations = pageContext.translated;
  const researchCategories = [
    ...data.wp.homeCategories.filter((category) => {
      if (lang === "tc") return category.language === "zh_TW";
      if (lang === "sc") return category.language === "zh_CN";
      if (lang === "en") return category.language === "en_US";
      return category.language === "en_US";
    }),
    {
      banner_url: "/book_icon.png",
      id: data.bookPage.id,
      name: data.bookPage.name,
      uri: data.bookPage.uri,
      language: data.bookPage.locale.locale,
      bottomLineColor: "blue",
    },
    {
      banner_url: "/speech_icon.png",
      id: data.speechPage.id,
      name: data.speechPage.name,
      uri: data.speechPage.uri,
      language: data.speechPage.locale.locale,
      bottomLineColor: "green",
    },
  ];

  useEffect(() => {
    window.scrollTo({ bottom: 0, behavior: "smooth" });
  }, []);

  return (
    <Layout pageTranslations={pageTranslations}>
      <Helmet>
        <title>{i18n.websiteName}</title>

        {/* og tags for facebook and linkedin (except og:type and og:locale) */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={i18n.websiteName} />
        <meta
          property="og:description"
          content={dividerText ? parse(dividerText)[0]?.props?.children : ""}
        />
        <meta property="og:image" content={bannerUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta
          property="og:locale"
          content={lang === "en" ? "en_US" : lang === "tc" ? "zh_TW" : "zh_CN"}
        />

        {/* og tags for twitter */}
        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" /> */}
        <meta name="twitter:title" content={i18n.websiteName} />
        <meta
          name="twitter:description"
          content={dividerText ? parse(dividerText)[0]?.props?.children : ""}
        />
        <meta name="twitter:image" content={bannerUrl} />
      </Helmet>

      <Banner title={bannerTitle} bannerUrl={bannerUrl} textOnRightHalfOnly />
      <Container disableGutters={isDesktop}>
        {isFeaturedArticlesAvailable && (
          <FeaturedPostSlider
            posts={featuredArticles}
            arrowPos={{
              buttonOffsetY: -213,
              leftButtonOffsetX: -38,
              rightButtonOffsetX: 38,
            }}
          />
        )}
        {isLatestArticlesAvailable && <PostList posts={latestArticles} />}
        {latestPeriodicals && latestPeriodicals.length > 0 && (
          <MultiPostSlider
            posts={latestPeriodicals}
            title={i18n.sectionHeadings.latestPeriodicals}
            arrowPos={{
              buttonOffsetY: -200,
              leftButtonOffsetX: -38,
              rightButtonOffsetX: 14,
            }}
          />
        )}
        {!isDesktop && (
          <Divider
            text={dividerText}
            buttonText={dividerButtonText}
            buttonHref={buttonHref}
          />
        )}
      </Container>
      {isDesktop && (
        <Divider
          text={dividerText}
          buttonText={dividerButtonText}
          buttonHref={buttonHref}
        />
      )}
      <Container disableGutters={isDesktop}>
        <SectionGridLayout
          title={i18n.sectionHeadings.publications}
          categories={researchCategories}
        />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query indexPageQuery($locale: String) {
    allWpPeriodical(
      sort: { fields: modified, order: DESC }
      filter: { locale: { locale: { eq: $locale } } }
      limit: 6
    ) {
      nodes {
        id
        title
        contentGenericGroup {
          thumbnail {
            sourceUrl
          }
        }
        uri
      }
    }
    wp {
      homeCategories {
        id
        language
        name
        uri
        banner_url
      }
    }
    speechPage: wpPage(
      pageType: { pageType: { eq: "10" } }
      locale: { locale: { eq: $locale } }
    ) {
      id
      uri
      name: title
      locale {
        locale
      }
    }
    bookPage: wpPage(
      pageType: { pageType: { eq: "9" } }
      locale: { locale: { eq: $locale } }
    ) {
      id
      uri
      name: title
      locale {
        locale
      }
    }
  }
`;

export default HomePage;
