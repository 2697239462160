import React from "react";
import {
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { CardActionArea } from "gatsby-theme-material-ui";

const PostListItem = ({ article }) => {
  const classes = useStyles();
  const isPhone = useMediaQuery(useTheme().breakpoints.down("xs"));

  return (
    <>
      <CardActionArea to={article.uri}>
        <CardContent className={classes.root}>
          <Grid container>
            <Grid item xs={12} md={5} lg={4}>
              <div className={classes.thumbnail}>
                {/* <CardMedia image={article.thumbnail} /> */}
                {article.thumbnail && (
                  <CardMedia className={classes.postItemImage} component="img" src={article.thumbnail} />
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={7} lg={8}>
              <div className={classes.info}>
                <Typography
                  variant={isPhone ? "h5" : "h4"}
                  className={classes.title}
                >
                  {article.title}
                </Typography>
                <Typography variant={isPhone ? "body2" : "body1"}>
                  {article.synopsis}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(4),
    },
  },
  thumbnail: {
    width: "100%",
    minHeight: "180px",
    [theme.breakpoints.up("sm")]: {
      minHeight: "240px",
    },
    backgroundColor: "#f1f1f1",
  },
  info: {
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      margin: 0,
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(4),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  postItemImage:{
    maxHeight: "180px",
    [theme.breakpoints.up("sm")]: {
      maxHeight: "240px",
    },
    objectFit: "contain"
  }
}));

export default PostListItem;
