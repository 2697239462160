import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import MoreButton from "./MoreButton";

const Divider = ({ text, buttonText, buttonHref }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Container>
        <Typography
          className={classes.text}
          component="div"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Container>
      {buttonText && (
        <MoreButton
          href={buttonHref}
          target="_blank"
          color="white"
          borderWidth="2px"
        >
          {buttonText}
        </MoreButton>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: "365px",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  text: {
    fontWeight: "300",
  },
}));

export default Divider;
